import { Component } from "@angular/core";
import { LoginService } from "../../services/login.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { EncrService } from "../../services/encryption/encr.service";
import { Router, NavigationEnd } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-dashboard",
  templateUrl: "login.component.html",
})
export class LoginComponent {
  loginForm: FormGroup;

  constructor(
    private loginService: LoginService,
    private formBuilder: FormBuilder,
    private encrService: EncrService,
    private router: Router,
    private tostr: ToastrService
  ) {
    this.loginForm = this.formBuilder.group({
      username: ["", [Validators.required]],
      password: ["", [Validators.required]],
    });
  }

  submitLogin() {
    const data = {
      userName: this.loginForm.value.username,
      password: this.loginForm.value.password,
    };

    this.loginService.login(data).subscribe(
      (response) => {
        if (response.code === 200) {
          if (response.result.userData.roles.indexOf("admin") >= 0) {
            localStorage.setItem("admin", this.encrService.encrypt(response));
            this.router.navigateByUrl("/tutorial");
          } else {
            this.tostr.error("Please login with admin credentials");
            this.loginForm.reset();
          }
          
        }
      },
      (error) => {
        console.log(error);
        this.tostr.error(error?.error?.message);
      }
    );
  }
}
