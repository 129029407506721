import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { timeout } from "rxjs/operators";
import { API } from "./api-constants/index";

@Injectable({
  providedIn: "root",
})
export class ListofprojectService {
  authorization = { headers: new HttpHeaders({ authorization: "True" }) };

  constructor(private http: HttpClient) {}

  public listOfprojects(projectOwnerId: number,query?:string): Observable<any> {
    return this.http.get(
      `${API.LIST_OF_PROJECT}?ownerId=${projectOwnerId}${query?query:''}`,
      this.authorization
    );
  }

  public getEarthtilesByProject(
    projectId: number,
    pageNumber = 1,
    pageSize?: number
  ): Observable<any> {
    let query = "&pageNo=" + pageNumber;
    if (pageSize) query += "&pageSize=" + pageSize;
    return this.http.get(
      `${API.LIST_OF_EARTHTILES_BY_PROJECT}?projectId=${projectId}${query}`,
      this.authorization
    );
  }

  public getProjectDetails(projectId: number): Observable<any> {
    return this.http.get(
      `${API.PROJECT_DETAILS}?projectId=${projectId}`,
      this.authorization
    );
  }

  public linkFileToProject(data): Observable<any> {
    return this.http
      .post(`${API.LINK_FILE_TO_PROJECT}`, data, this.authorization)
      .pipe(timeout(900000)); // 15 min
  }
  public linkFileToInvestment(data): Observable<any> {
    return this.http
      .post(`${API.LINK_FILE_TO_INVESTMENT}`, data, this.authorization)
      .pipe(timeout(900000)); // 15 min
  }
  public unlinkFileToProject(data): Observable<any> {
    return this.http.post(
      `${API.UNLINK_FILE_TO_PROJECT}`,
      data,
      this.authorization
    );
  }
  public unlinkFileToInvestment(data): Observable<any> {
    return this.http.post(
      `${API.UNLINK_FILE_TO_INVESTMENT}`,
      data,
      this.authorization
    );
  }

  public getQueue(query): Observable<any> {
    return this.http.get(`${API.PROJECT_QUEUE_LIST}${query}`, this.authorization);
  }
}
