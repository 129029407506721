import { INavData } from "@coreui/angular";

export const navItems: INavData[] = [
  {
    name: "Launch Videos",
    url: "/tutorial",
    icon: "icon-speedometer",
    badge: {
      variant: "info",
      text: "",
    },
  },
  {
    name: "Preferences",
    url: "/preferences",
    icon: "icon-speedometer",
    badge: {
      variant: "info",
      text: "",
    },
  },
  {
    name: "Allocate land",
    url: "/claimland",
    icon: "icon-speedometer",
    badge: {
      variant: "info",
      text: "",
    },
  },
  {
    name: "List of Land",
    url: "/listofland",
    icon: "icon-speedometer",
    badge: {
      variant: "info",
      text: "",
    },
  },
  {
    name: "List of Landtiles",
    url: "/landtile",
    icon: "icon-speedometer",
    badge: {
      variant: "info",
      text: "",
    },
  },
  {
    name: "Create Project",
    url: "/createproject",
    icon: "icon-speedometer",
    badge: {
      variant: "info",
      text: "",
    },
  },
  {
    name: "Project Queue",
    url: "/project-queue",
    icon: "icon-speedometer",
    badge: {
      variant: "info",
      text: "",
    },
  },
  {
    name: "List of Projects",
    url: "/listofproject",
    icon: "icon-speedometer",
    badge: {
      variant: "info",
      text: "",
    },
  },
  {
    name: "Auction",
    url: "/auction",
    icon: "icon-speedometer",
    badge: {
      variant: "info",
      text: "",
    },
  },
  {
    name: "Create Investment",
    url: "/investor",
    icon: "icon-speedometer",
    badge: {
      variant: "info",
      text: "",
    },
  },
  {
    name: "List Of Investment",
    url: "/investor/list",
    icon: "icon-speedometer",
    badge: {
      variant: "info",
      text: "",
    },
  },
  {
    name: "List Of Investortiles",
    url: "/investortiles",
    icon: "icon-speedometer",
    badge: {
      variant: "info",
      text: "",
    },
  },
  {
    name: "Investortiles Auction ",
    url: "/investortilesauction",
    icon: "icon-speedometer",
    badge: {
      variant: "info",
      text: "",
    },
  },
  {
    name: "Investor Password",
    url: "/investorpassword",
    icon: "icon-speedometer",
    badge: {
      variant: "info",
      text: "",
    },
  },
  {
    name: "Terms Conditions",
    url: "/terms",
    icon: "icon-speedometer",
    badge: {
      variant: "info",
      text: "",
    },
  },
  {
    name: "Users",
    url: "/users",
    icon: "icon-user",
    badge: {
      variant: "info",
      text: "",
    },
  },
  {
    name: "Logout",
    url: "/logout",
    icon: "icon-speedometer",
    badge: {
      variant: "info",
      text: "",
    },
  },
];
