import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { API } from "./api-constants/index";

@Injectable({
  providedIn: "root",
})
export class TutorialService {
  authorization = { headers: new HttpHeaders({ authorization: "True" }) };
  constructor(private http: HttpClient) {}

  public tutorialList(query?:string): Observable<any> {
    return this.http.get(`${API.GET_TUTORIAL}${query}`);
  }

  public addTutorial(data): Observable<any> {
    return this.http.post(`${API.ADD_TUTORIAL}`, data, this.authorization);
  }

  public updateTutorial(data): Observable<any> {
    return this.http.post(`${API.UPDATE_TUTORIAL}`, data, this.authorization);
  }

  public deleteTutorial(id): Observable<any> {
    return this.http.delete(
      `${API.DELETE_TUTORIAL}?tutorialId=${id}`,
      this.authorization
    );
  }
}
