import { Component, OnInit } from "@angular/core";
import { AnimationItem } from "lottie-web";
import { AnimationOptions } from "ngx-lottie";
import { LoaderService } from "../../services/loader.service";

@Component({
  selector: "app-http-loader",
  templateUrl: "./http-loader.component.html",
  styleUrls: ["./http-loader.component.css"],
})
export class HttpLoaderComponent implements OnInit {
  loading: boolean;
  options: AnimationOptions = {
    path: "/assets/data/loader.json",
  };

  animationCreated(animationItem: AnimationItem): void {}

  constructor(private loaderService: LoaderService) {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
  }
  ngOnInit() {}
}
