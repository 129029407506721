import { environment } from "../../../environments/environment";

const SERVER_URL = `${environment.API_URL}`;
const Version = "/api";

export const API = {
  LOGIN: `${SERVER_URL}/auth/login`,

  GET_USRES: `${SERVER_URL}/user/list`,
  GET_USERDETAILSBY_ID: `${SERVER_URL}/user/details?userId=`,

  GET_TUTORIAL: `${SERVER_URL}/tutorial`,
  ADD_TUTORIAL: `${SERVER_URL}/tutorial/add`,
  UPDATE_TUTORIAL: `${SERVER_URL}/tutorial/update`,
  DELETE_TUTORIAL: `${SERVER_URL}/tutorial`,

  CLAIM_LAND: `${SERVER_URL}/land/allocate`,

  LIST_OF_LANDOWNER_LAND: `${SERVER_URL}/land/list`,

  LIST_OF_LANDOWNER_LANDTILE: `${SERVER_URL}/landtile/list`,
  LANDTILE_DETAILS: `${SERVER_URL}/landtile/details`,
  LAND_UPDATE: `${SERVER_URL}/land/update`,
  LANDTILE_UPDATE: `${SERVER_URL}/landtile/update`,

  CREATE_PROJECT: `${SERVER_URL}/project/create`,
  LIST_OF_PROJECT: `${SERVER_URL}/project/list`,
  PROJECT_DETAILS: `${SERVER_URL}/project/details`,
  UPDATE_PROJECT: `${SERVER_URL}/project/update`,
  PROJECT_QUEUE_LIST: `${SERVER_URL}/project/createQueue`,
  LINK_FILE_TO_PROJECT: `${SERVER_URL}/project/linkFile`,
  UNLINK_FILE_TO_PROJECT: `${SERVER_URL}/project/unlinkFile`,

  LIST_OF_EARTHTILES: `${SERVER_URL}/earthtile/list`,
  LIST_OF_EARTHTILES_BY_PROJECT: `${SERVER_URL}/earthtile/project/list`,
  CREATE_AUCTION: `${SERVER_URL}/earthtile/auction/create`,

  FILE_UPLOAD: `${SERVER_URL}/file/upload`,
  TERMS_AND_CONDITION: `${SERVER_URL}/public/terms`,

  CLAIM_INVESTOR_LAND: `${SERVER_URL}/investment/create`,
  UPDATE_INVESTOR_LAND: `${SERVER_URL}/investortile/update`,
  LIST_INVESTOR_TILE: `${SERVER_URL}/investortile/details`,
  INVESTOR_TILE: `${SERVER_URL}/investortile/list`,
  INVESTMENT_LIST: `${SERVER_URL}/investment/list`,
  INVESTMENT_BYID: `${SERVER_URL}/investment/details`,
  INVESTORTIL_BY_INVESTMENTID: `${SERVER_URL}/investortile`,
  UPDATE_INVESTOR_PASSWORD: `${SERVER_URL}/investor/updatePassword`,
  CREATE_INVESTOR_AUCTION: `${SERVER_URL}/investortile/auction/create`,
  LINK_FILE_TO_INVESTMENT: `${SERVER_URL}/investment/linkFile`,
  UNLINK_FILE_TO_INVESTMENT: `${SERVER_URL}/investment/unlinkFile`,
  INVESTMENT_UPDATE: `${SERVER_URL}/investment/update`,
  SET_LAT_LONG: `${SERVER_URL}/admin/latLong`,
  UPDATE_USER: `${SERVER_URL}/user/role/update`,
};
