import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// Import Containers
import { DefaultLayoutComponent } from "./containers";
import { AuthGuard } from "./services/auth.guard";

import { P404Component } from "./views/error/404.component";
import { P500Component } from "./views/error/500.component";
import { LoginComponent } from "./views/login/login.component";
import { RegisterComponent } from "./views/register/register.component";

export const routes: Routes = [
  {
    path: "",
    canActivate: [AuthGuard],
    component: DefaultLayoutComponent,
    data: {
      title: "Home",
    },
    children: [
      {
        path: "tutorial",
        loadChildren: () =>
          import("./views/tutorials/tutorials.module").then(
            (m) => m.TutorialsModule
          ),
      },
      {
        path: "preferences",
        loadChildren: () =>
          import("./views/preferences/preferences.module").then(
            (m) => m.PreferencesModule
          ),
      },
      {
        path: "claimland",
        loadChildren: () =>
          import("./views/claim-land/claim-land.module").then(
            (m) => m.ClaimLandModule
          ),
      },
      {
        path: "listofland",
        loadChildren: () =>
          import("./views/listofland/listofland.module").then(
            (m) => m.ListoflandModule
          ),
      },
      {
        path: "listofproject",
        loadChildren: () =>
          import("./views/listofproject/listofproject.module").then(
            (m) => m.ListofprojectModule
          ),
      },
      {
        path: "landtile",
        loadChildren: () =>
          import("./views/landtile/landtile.module").then(
            (m) => m.LandtileModule
          ),
      },
      {
        path: "listoftiles",
        loadChildren: () =>
          import("./views/listoftiles/listoftiles.module").then(
            (m) => m.ListoftilesModule
          ),
      },
      {
        path: "auction",
        loadChildren: () =>
          import("./views/auction/auction.module").then((m) => m.AuctionModule),
      },
      {
        path: "createproject",
        loadChildren: () =>
          import("./views/createproject/createproject.module").then(
            (m) => m.CreateprojectModule
          ),
      },
      {
        path: "project-queue",
        loadChildren: () =>
          import("./views/project-queue/project-queue.module").then(
            (m) => m.ProjectQueueModule
          ),
      },
      {
        path: "investor",
        loadChildren: () =>
          import("./views/investor/investor.module").then(
            (m) => m.InvestorModule
          ),
      },
      {
        path: "investortiles",
        loadChildren: () =>
          import("./views/investor-tilelist/investor-tile-list.module").then(
            (m) => m.InvestorTileListModule
          ),
      },
      {
        path: "investortilesauction",
        loadChildren: () =>
          import("./views/investor-auction/investor-auction.module").then(
            (m) => m.AuctionModule
          ),
      },
      {
        path: "terms",
        loadChildren: () =>
          import("./views/terms-conditions/terms-condition.module").then(
            (m) => m.TermsConditionModule
          ),
      },
      {
        path: "logout",
        loadChildren: () =>
          import("./views/logout/logout.module").then(
            (m) => m.ListoftilesModule
          ),
      },
      {
        path: "investorpassword",
        loadChildren: () =>
          import("./views/investor-password/investor-password.module").then(
            (m) => m.InvestorPasswordModule
          ),
      },
      {
        path: "users",
        loadChildren: () =>
          import("./views/users/users.module").then(
            (m) => m.UsersModule
          ),
      },
    ],
  },
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "404",
    component: P404Component,
    data: {
      title: "Page 404",
    },
  },
  {
    path: "500",
    component: P500Component,
    data: {
      title: "Page 500",
    },
  },
  {
    path: "login",
    component: LoginComponent,
    data: {
      title: "Login Page",
    },
  },
  {
    path: "register",
    component: RegisterComponent,
    data: {
      title: "Register Page",
    },
  },
  { path: "**", component: P404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
