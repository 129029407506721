import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { EncrService } from './encryption/encr.service';

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  constructor(private encrService: EncrService,private router:Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = this.encrService.decrypt(localStorage.getItem("admin"));
    if(token)                  
        return true;
        else
        this.router.navigate(["/login"]);

  }
}