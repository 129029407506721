import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
// import { localDB } from 'src/app/_helpers/localstorage/index';
import { EncrService } from "../encryption/encr.service";
import { LoaderService } from "../loader.service";
import { Router } from "@angular/router";

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(
    private encrService: EncrService,
    private loaderService: LoaderService,
    private router:Router
  ) {}

  removeRequest(req: HttpRequest<any>) {
    if (req.url.includes("ignoreLoad")) return;
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.headers.get("authorization")) {
      const token = this.encrService.decrypt(localStorage.getItem("admin"))
        .result.token;
      request = request.clone({
        setHeaders: {
          Authorization: `${token}`,
        },
      });
    } else {
      request = request.clone({
        setHeaders: {
          "subscription-key": "earthtile-51bvl3aljK8AadlIA839jAvak",
        },
      });
    }

    if (!request.url.includes("ignoreLoad")) {
      this.requests.push(request);
      this.loaderService.isLoading.next(true);
    }
    return Observable.create((observer) => {
      const subscription = next.handle(request).subscribe(
        (event) => {
          if (event instanceof HttpResponse) {
            this.removeRequest(request);
            observer.next(event);
          }
        },
        (err) => {
          this.removeRequest(request);
          observer.error(err);
          if (err.status == 401) {
            this.router.navigate(["/login"]).then(() => localStorage.clear());
          }
        },
        () => {
          this.removeRequest(request);
          observer.complete();
        }
      );
      // remove request from queue when cancelled
      return () => {
        this.removeRequest(request);
        subscription.unsubscribe();
      };
    });
  }
}
